import type * as ShoAd from '@smd/sho-advertising-typings';
import type { Nullable } from '@smd/utilities';
import * as Core from '../../../core';
import { Targeting } from '../../Targeting';
import { Api } from '../Api';

export class BidderSettings extends Core.Service.Generic.State.Active<BidderSettings.Options> {
	protected override async executeSetup(abortSignal?: AbortSignal) {
		const { settings } = this.options;
		const { bidderAliases } = settings ?? {};
		const bidderSettings = this.#get();

		await Api.execute(function () {
			// Set up alias bidders:
			if (bidderAliases) {
				for (const { alias, adapterBidderCode } of bidderAliases) {
					this.aliasBidder(adapterBidderCode, alias);
				}
			}

			// Set up bidder settings:
			this.bidderSettings = bidderSettings;
		}, abortSignal);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		await Api.execute(function () {
			// Tear down alias bidders:
			// n/a - impossible in prebid.js

			// Tear down bidder settings:
			this.bidderSettings = {};
		}, abortSignal);
	}

	#get(): Pbjs.BidderSettings {
		return {
			standard: {
				adserverTargeting: Array.from(Targeting.defaultValue),
			},
			...Object.fromEntries<Pbjs.BidderSettings.Specific.Attributes>(this.#getSpecificEntries()),
		};
	}

	*#getSpecificEntries(): Generator<BidderSettings.SpecificEntry> {
		const { biddersSettings = [], exchangeRates } = this.options.settings ?? {};

		for (const { bidderCode, currencyCode, revenueFactor } of biddersSettings) {
			const exchangeRate = exchangeRates?.[currencyCode] ?? 1;

			yield [
				bidderCode,
				{
					bidCpmAdjustment: (bidCpm, bid) => {
						const adjustedPrice = Targeting.AdjustedPrice.from(bidCpm, revenueFactor, exchangeRate);
						BidderSettings.logBid(bid, adjustedPrice);
						return adjustedPrice;
					},
				},
			] as const;
		}
	}

	static logBid(bid: Pbjs.Bid.Args.Common, adjustedPrice: number) {
		try {
			const { adUnitCode, bidder } = bid;
			const originalCpm = bid.cpm.toFixed(3);
			const adjustedCpm = Targeting.AdjustedPrice.format(adjustedPrice);
			const priceBucket = Targeting.PriceBucket.from(adjustedPrice);
			const message = `BID: ${originalCpm} > DKK: ${adjustedCpm} (${priceBucket})`;

			Core.log('PREBID', bidder, adUnitCode, message, bid);
		} catch (error) {
			Core.log.error('PREBID', 'Bid', 'Logging', 'Failed to log bid', { bid, error });
		}
	}
}

export namespace BidderSettings {
	export type Options = Readonly<{ settings: Nullable<Options.Settings> }>;

	export namespace Options {
		export type Settings = Readonly<ShoAd.PrebidSettingsPageProperties>;
	}

	export type SpecificEntry = readonly [
		bidderCode: string,
		attributes: Pbjs.BidderSettings.Specific.Attributes,
	];
}
