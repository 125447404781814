/** Intermingles listings, items, and ads into a new list. */
export function* intermingle<
	TListing,
	TAd extends intermingle.Item,
	TContent extends intermingle.Item,
>({
	listings,
	intermingle: { ads = [], content = [] },
	columnsCount = 1,
	offset = 0,
	pushRemainingAds = false,
}: intermingle.Options<TListing, TAd, TContent>): Generator<
	TListing | TAd | TContent,
	void,
	undefined
> {
	const rowState = new intermingle.RowState(offset, columnsCount);
	const adsQueue = intermingle.Queue.byType(ads, offset, rowState.current);
	const contentQueue = intermingle.Queue.byType(content, offset, rowState.current);

	let intermingledRowsCount = 0;

	const intermingledGenerator = intermingle.byRow(
		intermingle.byIndex(listings, adsQueue.indexBased, contentQueue.indexBased, offset),
		adsQueue.rowBased,
		contentQueue.rowBased,
		rowState,
		() => void ++intermingledRowsCount,
	);

	if (!pushRemainingAds) {
		yield* intermingledGenerator;
		return;
	}

	const intermingled = new Set(intermingledGenerator);
	yield* intermingled.values();

	yield* intermingle.remainingAds(adsQueue, offset, {
		intermingledItems: intermingled.size,
		intermingledRows: intermingledRowsCount,
		columns: columnsCount,
	});
}

export namespace intermingle {
	export type Options<TListing, TAd extends intermingle.Item, TContent extends intermingle.Item> = {
		listings: ReadonlyArray<TListing>;
		intermingle: Options.Intermingle<TAd, TContent>;
		columnsCount?: number;
		pushRemainingAds?: boolean;
		offset?: number;
	};

	export namespace Options {
		export type Intermingle<TAd extends intermingle.Item, TContent extends intermingle.Item> = {
			ads?: ReadonlyArray<TAd>;
			content?: ReadonlyArray<TContent>;
		};
	}
}

import * as _byIndex from './byIndex';
import * as _byRow from './byRow';
import * as _Compare from './Compare';
import * as _Item from './Item';
import * as _Queue from './Queue';
import * as _remainingAds from './remainingAds';
import * as _RowState from './RowState';

export namespace intermingle {
	export import byIndex = _byIndex.byIndex;
	export import byRow = _byRow.byRow;
	export import Compare = _Compare;
	export import Item = _Item;
	export type Item = _Item.Item;
	export import Queue = _Queue;
	export import remainingAds = _remainingAds.remainingAds;
	export import RowState = _RowState.RowState;
}
