import type { Item } from './Item';
import type * as Queue from './Queue';
import { RowState } from './RowState';

/** Intermingles remaining ads into the list. Used on the last page of search results. */
export function* remainingAds<TRowBased extends Item.RowBased, TIndexBased extends Item.IndexBased>(
	adsQueue: Queue.byType<TRowBased, TIndexBased>,
	offset: number,
	count: remainingAds.Count,
): Generator<TRowBased | TIndexBased, void, undefined> {
	// Pretend that we haven't intermingled rows before, so that we know where to start pushing
	// the rest of the ads. Subtract 1 to pretend that we are in the loop from the previous.
	const lastIndex = count.intermingledItems + offset - count.intermingledRows - 1;
	const lastRow = Math.ceil(lastIndex / count.columns);

	const lastUsedRowBasedIndex = adsQueue.rowBased.findIndex(
		({ intermingleRow }) => intermingleRow >= lastRow,
	);

	const lastUsedIndexBasedIndex = adsQueue.indexBased.findIndex(
		({ intermingleIndex }) => intermingleIndex >= lastIndex,
	);

	const remainingRowBased = adsQueue.rowBased.slice(lastUsedRowBasedIndex);
	const remainingIndexBased = adsQueue.indexBased.slice(lastUsedIndexBasedIndex);
	const rowState = new RowState(offset, count.columns);

	for (const [index, result] of remainingIndexBased.entries()) {
		while (remainingRowBased[0]?.intermingleRow === rowState.current) {
			yield remainingRowBased[0];
			remainingRowBased.shift();
		}

		yield result;

		rowState.ifLastColumnMoveToNextRow(index);
	}

	yield* remainingRowBased;
}

export namespace remainingAds {
	export type Count = {
		intermingledItems: number;
		intermingledRows: number;
		columns: number;
	};
}
