import * as Core from '../../../core';
import { Api } from '../Api';

export class Tracking extends Core.Service.Generic.State.Active<Tracking.Options> {
	protected override async executeSetup(abortSignal?: AbortSignal) {
		await Api.execute(function () {
			for (const [event, handler] of Tracking.eventHandlers) this.onEvent(event, handler);
		}, abortSignal);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		await Api.execute(function () {
			for (const [event, handler] of Tracking.eventHandlers) this.offEvent(event, handler);
		}, abortSignal);
	}
}

export namespace Tracking {
	export type Options = Readonly<unknown>;

	export const eventHandlers = [
		['auctionInit', handleAuctionInit],
		['auctionEnd', handleAuctionEnd],
		['bidWon', handleBidWon],
	] as const;

	export function handleAuctionInit(auctionDetails: Pbjs.Bid.Args.Auction) {
		Core.log(
			'PREBID',
			'AuctionInit',
			`units (${auctionDetails.adUnits.length})`,
			auctionDetails.adUnits.map(({ code }) => code).join(', '),
			auctionDetails,
		);
	}

	export function handleAuctionEnd(auctionDetails: Pbjs.Bid.Args.Auction) {
		Core.log(
			'PREBID',
			'AuctionEnd',
			`bids (${auctionDetails.bidsReceived.length})`,
			auctionDetails.adUnits.map(({ code }) => code).join(', '),
			auctionDetails,
		);
	}

	export function handleBidWon(bid: Pbjs.Bid.Args.Common) {
		Core.log('PREBID', '🏆 BidWon 🏆', bid.bidderCode, bid.adUnitCode, bid);
	}
}
