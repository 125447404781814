import * as Core from '../../../core';
import { Api } from '../Api';

export class AdUnits extends Core.Service.Generic.State.Active<AdUnits.Options> {
	protected override async executeSetup(abortSignal?: AbortSignal) {
		const { adUnits } = this.options;

		await Api.execute(function () {
			if (adUnits.length) this.addAdUnits(adUnits);
		}, abortSignal);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		const { adUnits } = this.options;
		const adUnitCodes = adUnits.map(({ code }) => code);

		await Api.execute(function () {
			if (adUnitCodes.length) this.removeAdUnit(adUnitCodes);
		}, abortSignal);
	}
}

export namespace AdUnits {
	export type Options = Readonly<{ adUnits: ReadonlyArray<Pbjs.AdUnit> }>;
}
