import { canUseDOM } from '@smd/utilities';
import {
  SmdBasSrpModelsFilterOption,
  SmdBasSrpModelsFilterOptionValue,
  SmdBasSrpModelsSearchRequest,
  SmdBasSrpWebApiModelsTracking,
} from 'types/api';

export const formatNumber = Intl.NumberFormat('da-dk', {
  maximumFractionDigits: 1,
}).format;

export const formatDKK = new Intl.NumberFormat('da-dk', {
  style: 'currency',
  currency: 'DKK',
  maximumFractionDigits: 0,
}).format;

export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

/**
 * This returns all filters ungrouped and without nesting
 */
export const flattenFilters = (
  filterOptions: SmdBasSrpModelsFilterOption[] = []
): SmdBasSrpModelsFilterOption[] =>
  filterOptions.reduce((prev, curr) => {
    if (curr.type === 'Grouping') {
      return prev.concat(
        flattenFilters(curr.filterOptions as SmdBasSrpModelsFilterOption[])
      );
      // TODO: Just validate that curr.filterOptions is not null e.g. filter(isNotNull)
    }
    return prev.concat(curr);
  }, [] as SmdBasSrpModelsFilterOption[]);

/**
 * Flatten option values with nested option values
 * @param options Option values to flatten
 * @param level Used to indicate at which level this option was seen
 * @returns An array of options and their nested options marked with a level property indicating at which level they existed before
 */
export const flattenOptionValues = (
  options: SmdBasSrpModelsFilterOptionValue[],
  level: number = 0
): SmdBasSrpModelsFilterOptionValue[] =>
  options.reduce((prev, curr) => {
    const option = { ...curr, level };

    if (option.optionValues && option.optionValues.length > 0) {
      return prev
        .concat(option)
        .concat(flattenOptionValues(option.optionValues, level + 1));
    }

    return prev.concat(option);
  }, [] as SmdBasSrpModelsFilterOptionValue[]);

export const countActiveFilters = (
  filters: SmdBasSrpModelsFilterOption[],
  searchRequest: SmdBasSrpModelsSearchRequest,
  initial: Record<string, number> = {}
): Record<string, number> => {
  return filters
    .filter((f) => f.type === 'Grouping') // We only want to count for groups
    .reduce((acc, filter) => {
      // All groups should have filterOptions, but we do this to avoid typescript warnings

      if (!!filter.filterOptions) {
        // We count any child groups
        const nestedGroups = filter.filterOptions.filter(
          (f) => f.type === 'Grouping'
        );

        const nested = countActiveFilters(nestedGroups, searchRequest, initial);

        const nestedCount = nestedGroups
          .map((f) => f.key)
          .reduce((acc, key) => nested[key!] + acc, 0);

        const count = filter.filterOptions.reduce((prev, curr) => {
          const selectedFiltersForKey =
            searchRequest.selectedFilters?.[curr.key!]; // TODO: Key must be a required value

          // We only want to count filters that actually have a value in the searchRequest
          // TODO: This is wrong! E.g. kontantpris is default selected, if it is deselected, no values exist for that group, so it is not counted
          if (!selectedFiltersForKey) return prev;

          switch (curr.type) {
            case 'Range':
            case 'Numeric':
            case 'Text':
              // Any value in Range, Numeric or Text counts as an active filter
              return prev + 1;
            case 'SingleSelection': {
              const ov = curr.optionValues?.find(
                (ov) => ov.value === selectedFiltersForKey.value?.value
              );
              return !ov ? prev : prev + 1;
            }
            case 'MultiSelection':
              const selectedValues = selectedFiltersForKey.values?.map(
                (s) => s.value
              );
              const ov = curr.optionValues?.filter((ov) =>
                selectedValues?.includes(ov.value)
              );
              return !ov ? prev : prev + ov.length;
            case 'Boolean':
              return selectedFiltersForKey.value?.booleanValue
                ? prev + 1
                : prev;
            default:
              return prev;
          }
        }, 0);

        return {
          ...acc,
          ...nested,
          [filter.key!]: count + nestedCount,
        };
      }

      return acc;
    }, initial);
};

/**
 * Updates the data layer with the provided data and pulse object.
 * Note: this happens more frequently than pageviews
 * @param {SmdBasSrpWebApiModelsTracking} options - The options object containing the data and pulse object.
 */
export const updateDataLayer = ({
  dataLayer: incomingDataLayer,
  pulse,
}: SmdBasSrpWebApiModelsTracking) => {
  if (!canUseDOM()) return;
  const dataLayer = (window.dataLayer = window.dataLayer || []);

  if (!!incomingDataLayer) {
    dataLayer.push(incomingDataLayer as any);
  }

  if (!!pulse) {
    dataLayer.push({ pulse });
  }
};

export const getPulsePageObject = () => {
  if (!canUseDOM()) return;

  let pageObject = null;
  const dataLayer = window.dataLayer as any;
  if (dataLayer && typeof dataLayer.getPulse === 'function') {
    const pulseObject = dataLayer.getPulse();
    pageObject = pulseObject?.pulse ?? {
      deployTag: 'frontendFallback',
      object: {
        '@type': 'Listing',
        id: 'sdrn:bilbasen:listing:srp',
        name: 'srp',
      },
    };
  }
  return pageObject;
};

/*
 * Compares deeply nested objects and returns true if they are equal, false if they are not.
 */
export const deepComparison = (first: any, second: any) => {
  /* Checking if the types and values of the two arguments are the same. */
  if (first === second) return true;

  /* Checking if any arguments are null */
  if (first === null || second === null) return false;

  /* Checking if any argument is none object */
  if (typeof first !== 'object' || typeof second !== 'object') return false;

  /* Using Object.getOwnPropertyNames() method to return the list of the objects’ properties */
  let first_keys = Object.getOwnPropertyNames(first);

  let second_keys = Object.getOwnPropertyNames(second);

  /* Checking if the objects' length are same*/
  if (first_keys.length !== second_keys.length) return false;

  /* Iterating through all the properties of the first object with the for of method*/
  for (let key of first_keys) {
    /* Making sure that every property in the first object also exists in second object. */
    if (!Object.hasOwn(second, key)) return false;

    /* Using the deepComparison function recursively (calling itself) and passing the values of each property into it to check if they are equal. */
    if (deepComparison(first[key], second[key]) === false) return false;
  }

  /* if no case matches, returning true */
  return true;
};
