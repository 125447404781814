import { as, PromiseWithResolvers } from '@smd/utilities';
import { hasGoogleConsent } from '@smd/cmp-sourcepoint';
import type * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../core';
import * as namespace from './.namespace';

class ApiLoader extends Core.ApiLoader<Api> {
	override readonly globalKey = 'adn';
	protected override readonly namespace = namespace;
	protected override readonly scriptUrl = 'https://cdn.adnuntius.com/adn.js';

	override async execute<R>(executor: Core.ApiLoader.Executor<Api, R>, abortSignal?: AbortSignal) {
		abortSignal?.throwIfAborted();

		const globalRef = await this.load();
		const { promise, resolve, reject } = PromiseWithResolvers.of<R>();

		globalRef.calls.push(() => {
			try {
				abortSignal?.throwIfAborted();
				resolve(executor.apply(globalRef, [abortSignal]));
			} catch (error) {
				reject(error);
			}
		});

		return await promise;
	}

	protected override async resolveReference() {
		const globalRef = window[this.globalKey];
		if (!globalRef) throw new ApiLoader.UnavailableError(this.globalKey);

		const { promise, resolve, reject } = PromiseWithResolvers.of<Api>();

		try {
			globalRef.calls.push(() => resolve(globalRef));
		} catch (error) {
			reject(error);
		}

		return await promise;
	}

	protected override async shouldLoad() {
		return !(await hasGoogleConsent());
	}

	protected override async prepare() {
		const globalRef = (window[this.globalKey] ??= as({}));
		(globalRef.calls as typeof globalRef.calls | undefined) ??= new Array();

		return Promise.resolve();
	}
}

/** @see {@link https://docs.adnuntius.com/adnuntius-advertising/requesting-ads/intro/adn-request} */
export const Api = new ApiLoader();

export type Api = {
	calls: Array<() => void>;
	request(config: Api.Config): void;
	clearDivs(): void;
};

export namespace Api {
	export type Config = {
		adUnits: Array<Config.AdUnit>;
		useCookies: boolean;
		isolateFrame: boolean;
		gdpr: 0 | 1;
		consentString?: string;
		context?: string;
		onError(error: unknown): void;
	};

	export namespace Config {
		export type AdUnit = ShoAd.AdnuntiusDisplayAdSlotProperties & {
			requestParams?: AdUnit.RequestParams;
			onNoMatchedAds?(event: unknown): void;
			onImpressionResponse?(event: unknown): void;
			onVisible?(event: AdUnit.ViewEvent): void;
			onViewable?(event: AdUnit.ViewEvent): void;
		};

		export namespace AdUnit {
			export type RequestParams = ShoAd.AdnuntiusRequestParameters & { proximity?: number };

			export type ViewEvent = {
				adId: string;
				auId: string;
				creativeId: string;
				viewability: unknown;
				widgetId: string;
			};
		}
	}

	export type Window = { [Api.globalKey]?: Api | undefined };
}

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window extends Api.Window {}
}
