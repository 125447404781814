import { as, PromiseWithResolvers } from '@smd/utilities';
import { hasGoogleConsent } from '@smd/cmp-sourcepoint';
import * as Core from '../../core';
import * as namespace from './.namespace';

class ApiLoader extends Core.ApiLoader<Api> {
	override readonly globalKey = 'googletag';
	protected override readonly namespace = namespace;
	protected override readonly scriptUrl = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

	override async execute<R>(executor: Core.ApiLoader.Executor<Api, R>, abortSignal?: AbortSignal) {
		abortSignal?.throwIfAborted();

		const globalRef = await this.load();
		const { promise, resolve, reject } = PromiseWithResolvers.of<R>();

		globalRef.cmd.push(() => {
			try {
				abortSignal?.throwIfAborted();
				resolve(executor.apply(globalRef, [abortSignal]));
			} catch (error) {
				reject(error);
			}
		});

		return await promise;
	}

	protected override async resolveReference() {
		const globalRef = window[this.globalKey];
		if (!globalRef) throw new ApiLoader.UnavailableError(this.globalKey);

		const { promise, resolve, reject } = PromiseWithResolvers.of<Api>();

		globalRef.cmd.push(() => {
			globalRef.apiReady
				? resolve(globalRef)
				: reject(new Error(`${this.globalKey}.apiReady is false`));
		});

		return await promise;
	}

	protected override async shouldLoad() {
		return await hasGoogleConsent();
	}

	protected override async prepare() {
		const globalRef = (window[this.globalKey] ??= as({}));
		(globalRef.cmd as typeof globalRef.cmd | undefined) ??= new Array();

		return Promise.resolve();
	}
}

export const Api = new ApiLoader();

export type Api = googletag.Googletag;

export namespace Api {
	export type Window = { [Api.globalKey]?: Api | undefined };
}

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window extends Api.Window {}
}
