import * as Core from '../../../core';
import * as AdUnits from './AdUnits';

export const from = ({
	adTypeProperties: pageProperties,
	intermingleItems,
	isSuccess,
	positionItems,
}: from.Options) => {
	if (!isSuccess) return null;
	if (!Core.TypeGuard.Display.Properties.Page.has(pageProperties)) return null;

	const {
		prebidSettings: settings = null,
		prebid: config,
		prebidBidderConfigs: bidderConfigs = null,
	} = pageProperties.display;

	if (!config) return null;

	const adUnits = Array.from(AdUnits.from(...positionItems, ...intermingleItems));
	if (!adUnits.length) return null;

	return { adUnits, bidderConfigs, config, settings } as const;
};

export namespace from {
	export type Options = Core.Config;
}
