import type { Nullable } from '@smd/utilities';
import * as AdjustedPrice from './AdjustedPrice';
import * as Bid from './Bid';

export function getWinningId(auctionId: string) {
	try {
		let winning: Nullable<Readonly<{ dealId: string | number; price: number }>>;

		for (const bidResponse of Bid.Response.findByAuctionId(auctionId)) {
			const { dealId, adUnitCode, adserverTargeting } = bidResponse;

			if (!dealId) continue;
			if (!adUnitCode.includes('top')) continue;

			const adjustedPrice = AdjustedPrice.fromTargeting(adserverTargeting);
			if (adjustedPrice <= (winning?.price ?? 0)) continue;

			winning = { dealId, price: adjustedPrice } as const;
		}

		return winning ? `${winning.dealId}` : null;
	} catch {
		return null;
	}
}
