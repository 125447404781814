import { ensure } from '@smd/utilities';

export namespace Response {
	export function* findByAuctionId(auctionId: string) {
		const events = ensure(window.pbjs).getEvents();

		for (const event of events) {
			if (!is(event)) continue;
			if (event.args.auctionId !== auctionId) continue;

			yield event.args;
		}
	}

	export function is(event: Pbjs.Bid): event is Pbjs.Bid.Response {
		return event.eventType === 'bidResponse';
	}
}
