import type { Item } from './Item';
import type { RowState } from './RowState';

/** Intermingles items that are intermingled by row. */
export function* byRow<
	TIndexBasedIntermingle,
	TRowBasedAd extends Item.RowBased,
	TRowBasedContent extends Item.RowBased,
>(
	indexBasedIntermingledItems: Iterable<TIndexBasedIntermingle>,
	rowBasedAdsQueue: Array<TRowBasedAd>,
	rowBasedContentQueue: Array<TRowBasedContent>,
	rowState: RowState,
	countIntermingledRow: () => void,
) {
	let count = 0;

	for (const indexBasedIntermingledItem of indexBasedIntermingledItems) {
		while (rowBasedAdsQueue[0]?.intermingleRow === rowState.current) {
			yield rowBasedAdsQueue[0];
			rowBasedAdsQueue.shift();
			countIntermingledRow();
		}

		while (rowBasedContentQueue[0]?.intermingleRow === rowState.current) {
			yield rowBasedContentQueue[0];
			rowBasedContentQueue.shift();
			countIntermingledRow();
		}

		yield indexBasedIntermingledItem;

		rowState.ifLastColumnMoveToNextRow(count);
		++count;
	}
}
