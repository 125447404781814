import { Debouncer, ensure } from '@smd/utilities';
import * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../../core';
import type * as Prebid from '../../../prebid';
import { Api } from '../Api';
import type { Config } from '../Config';
import type { Context } from '../Context';

export type State = Core.Service.Generic.State.Of<State.Active>;

export namespace State {
	export class Active extends Core.Service.Generic.State.Active<Active.Options> {
		static readonly from = (options: Active.Options) => new this(options);

		readonly activate: Context = this.#activate.bind(this);

		readonly #debouncer = new Debouncer<string>(16, this.abortSignal);
		readonly #queue: Active.Queue = new Array<string>();
		readonly #tracking = new Tracking(this.options);
		readonly #ppid = new Ppid(this.options);
		readonly #lazyLoad = new LazyLoad(this.options);
		readonly #sizeMapping = new SizeMapping(this.options);

		protected override async executeSetup(abortSignal?: AbortSignal) {
			await this.#tracking.setup(abortSignal);
			await this.#ppid.setup(abortSignal);
			await this.#lazyLoad.setup(abortSignal);
			await this.#sizeMapping.setup(abortSignal);

			const slots = this.#processQueue(abortSignal);
			const { targeting } = this.options;

			const customTargeting = window.DisplayAdsUtilities?.customTargeting ?? {};

			await Api.execute(function () {
				const keyValues = Object.entries({ ...targeting, ...customTargeting });

				for (const [key, values] of keyValues) this.pubads().setTargeting(key, values);

				this.pubads().disableInitialLoad();
				this.pubads().enableSingleRequest();
				this.enableServices();
			}, abortSignal);

			await this.#requestAds(slots, this.abortSignal);

			this.#runActivateLoop().catch((error: unknown) => {
				if (this.isDestroyed()) return;

				Core.log.error('ADMANAGER', 'Slots', 'Activate', 'Failed to request ads for queued slots', {
					error,
				});
			});
		}

		protected override async executeDestroy(abortSignal?: AbortSignal) {
			await this.#tracking.destroy(abortSignal);
			await this.#ppid.destroy(abortSignal);
			await this.#lazyLoad.destroy(abortSignal);
			await this.#sizeMapping.destroy(abortSignal);

			await Api.execute(function () {
				this.destroySlots();
				this.pubads().clearTargeting();
			}, abortSignal);

			/**
			 * For some reason, using `googletag.destroySlots()` doesn't seem to clean up all slots
			 * properly. From the looks of it only unfilled ad slots are affected, but unfortunately it
			 * results in those ad slots being unable to be filled with ads later on. As a workaround,
			 * we'll attempt to do the cleanup ourselves.
			 */
			const slotsQuerySelector = this.options.slots.map(({ id }) => `#${id}`).join(', ');
			const slotElements = window.document.querySelectorAll(slotsQuerySelector).values();

			for (const slotElement of slotElements) {
				if (!(slotElement instanceof HTMLElement)) continue;

				try {
					// Remove attribute left behind by googletag:
					slotElement.removeAttribute('data-google-query-id');

					// Remove child nodes left behind by googletag:
					slotElement.replaceChildren();

					// Remove CSS properties left behind by googletag:
					slotElement.style.removeProperty('display');
				} catch {
					// Do nothing, try the next element...
				}
			}
		}

		#activate(id: string) {
			if (this.isDestroyed()) return;

			if (this.isSetUp()) this.#debouncer.add(id);
			else this.#queue.push(id);

			return async () => await this.#deactivate(id);
		}

		async #deactivate(id: string) {
			if (this.isDestroyed()) return false;

			return await Api.execute(function () {
				for (const slot of this.pubads().getSlots()) {
					if (slot.getSlotElementId() === id) return this.destroySlots([slot]);
				}

				return false;
			});
		}

		async #runActivateLoop() {
			for await (const idsChunk of this.#debouncer) {
				if (this.isDestroyed()) return;

				this.#queue.push(...idsChunk);
				await this.#requestAds(this.#processQueue(this.abortSignal), this.abortSignal);
			}
		}

		async *#processQueue(abortSignal?: AbortSignal) {
			while (this.#queue.length) {
				const slotId = ensure(this.#queue.shift());

				const slot =
					(await this.#createSlot(slotId, abortSignal)) ??
					(await this.#createOutOfPageSlot(slotId, abortSignal));

				if (!slot) {
					const { options } = this;
					Core.log.error('ADMANAGER', 'Slots', 'Config', 'Slot not found', { slotId, options });
					continue;
				}

				yield slot;
			}
		}

		async #createSlot(slotId: string, abortSignal?: AbortSignal) {
			const config = this.options.slots.find(slot => slot.id === slotId);
			if (!config) return null;

			const { collapseEmptyDiv, id, sizes, sizeMappingName, targeting } = config;
			const { path = this.options.path } = config;
			const sizeMapping = this.#sizeMapping;

			return await Api.execute(function () {
				const slot = this.defineSlot(path, sizes, id);

				if (sizeMappingName) {
					const sizeMappingArray = sizeMapping.get(sizeMappingName);
					if (sizeMappingArray) slot.defineSizeMapping(sizeMappingArray);
				}

				if (collapseEmptyDiv) {
					const [collapseUnfilled, collapseBeforeAdFetch] = collapseEmptyDiv;
					slot.setCollapseEmptyDiv(collapseUnfilled, collapseBeforeAdFetch);
				}

				for (const [key, values] of Object.entries(targeting)) slot.setTargeting(key, values);

				slot.addService(this.pubads());

				return slot;
			}, abortSignal);
		}

		async #createOutOfPageSlot(slotId: string, abortSignal?: AbortSignal) {
			const config = this.options.outOfPageSlots?.find(slot => slot.id === slotId);
			if (!config) return null;

			const { id, path = this.options.path } = config;

			return await Api.execute(function () {
				const slot = this.defineOutOfPageSlot(path, id);
				slot.addService(this.pubads());

				return slot;
			}, abortSignal);
		}

		async #requestAds(slots: AsyncIterable<googletag.Slot>, abortSignal?: AbortSignal) {
			const selectedSlots = new Array<googletag.Slot>();
			const adUnitCodes = new Array<string>();

			for await (const slot of slots) {
				await Api.execute(function () {
					this.display(slot);
				}, abortSignal);

				selectedSlots.push(slot);
				adUnitCodes.push(slot.getSlotElementId());
			}

			if (!selectedSlots.length) {
				Core.log.warn(
					'REQUEST',
					ShoAd.AdType.Display,
					'Slots',
					'Aborted due to no slots registered',
				);
				return;
			}

			try {
				await this.#runAuctionAndApplyTargeting(adUnitCodes, abortSignal);
			} catch (error) {
				Core.log.error('ADMANAGER', 'Prebid', 'Auction', 'Failed, attempting to continue...', {
					error,
				});
			}

			await Api.execute(function () {
				Core.log('REQUEST', ShoAd.AdType.Display, 'Slots', 'Initiated', { selectedSlots });
				this.pubads().refresh(selectedSlots);
			}, abortSignal);
		}

		async #runAuctionAndApplyTargeting(
			adUnitCodes: ReadonlyArray<string>,
			abortSignal?: AbortSignal,
		) {
			const { prebid } = this.options;
			if (!prebid) return;

			const auction = await prebid.runAuction(adUnitCodes, abortSignal);
			if (!auction) return;

			await prebid.execute(function () {
				this.setTargetingForGPTAsync(adUnitCodes);
			}, abortSignal);

			const { winningTakeoverDealId } = auction;

			if (winningTakeoverDealId) {
				await Api.execute(function () {
					this.pubads().setTargeting('hb_takeover_dealId', winningTakeoverDealId);
				}, abortSignal);
			}
		}
	}

	export namespace Active {
		export type Options = LazyLoad.Options &
			Ppid.Options &
			SizeMapping.Options &
			Tracking.Options &
			Config &
			Readonly<{
				prebid?: Prebid.Service.Context;
			}>;

		export type Queue = Array<string>;
	}
}

import * as _LazyLoad from './LazyLoad';
import * as _Ppid from './Ppid';
import * as _SizeMapping from './SizeMapping';
import * as _Tracking from './Tracking';

export namespace State {
	export import LazyLoad = _LazyLoad.LazyLoad;
	export import Ppid = _Ppid.Ppid;
	export import SizeMapping = _SizeMapping.SizeMapping;
	export import Tracking = _Tracking.Tracking;
}
