import type * as ShoAd from '@smd/sho-advertising-typings';
import type { Nullable } from '@smd/utilities';
import * as Core from '../../../core';
import { Api } from '../Api';

export class Config extends Core.Service.Generic.State.Active<Config.Options> {
	protected override async executeSetup(abortSignal?: AbortSignal) {
		const { config, bidderConfigs } = this.options;

		await Api.execute(function () {
			// Set up global config:
			this.setConfig(config);

			if (!bidderConfigs) return;

			// Set up bidder-specific configs:
			for (const bidderConfig of bidderConfigs) this.setBidderConfig(bidderConfig);
		}, abortSignal);
	}

	protected override async executeDestroy(abortSignal?: AbortSignal) {
		const { bidderConfigs } = this.options;

		await Api.execute(function () {
			// Reset global config:
			this.setConfig({});

			if (!bidderConfigs) return;

			// Reset bidder-specific configs:
			for (const { bidders } of bidderConfigs) this.setBidderConfig({ bidders, config: {} });
		}, abortSignal);
	}
}

export namespace Config {
	export type Options = Readonly<{
		config: Pbjs.Config;
		bidderConfigs: Nullable<ReadonlyArray<Options.BidderConfig>>;
	}>;

	export namespace Options {
		export type BidderConfig = Readonly<ShoAd.PrebidBidderConfig>;
	}
}
