import type { Item } from './Item';

/** Intermingles items that are intermingled by index. */
export function* byIndex<
	TListing,
	TIndexBasedAd extends Item.IndexBased,
	TIndexBasedContent extends Item.IndexBased,
>(
	listings: ReadonlyArray<TListing>,
	indexBasedAdsQueue: Array<TIndexBasedAd>,
	indexBasedContentQueue: Array<TIndexBasedContent>,
	offset: number,
) {
	for (const [index, listing] of listings.entries()) {
		const currentIndex = index + offset;

		while (indexBasedAdsQueue[0]?.intermingleIndex === currentIndex) {
			yield indexBasedAdsQueue[0];
			indexBasedAdsQueue.shift();
		}

		while (indexBasedContentQueue[0]?.intermingleIndex === currentIndex) {
			yield indexBasedContentQueue[0];
			indexBasedContentQueue.shift();
		}

		yield listing;
	}
}
