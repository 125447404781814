export type Targeting = {
	[K in string]: Pbjs.BidderSettings.Common.Standard.AdserverTargeting.Val;
};

export namespace Targeting {
	export const defaultValue = {
		/* eslint-disable @typescript-eslint/naming-convention */
		hb_bidder: ({ bidder }) => bidder,
		hb_size: ({ size }) => size,
		hb_adid: ({ adId }) => adId,
		hb_dealId: ({ dealId }) => (dealId ? `${dealId}` : ''),
		hb_pb: ({ cpm }) => Targeting.PriceBucket.from(cpm),
		g_adid: ({ adId }) => adId,
		g_prebid_version: () => window.pbjs?.version ?? 'unknown',
		/* eslint-enable @typescript-eslint/naming-convention */

		*[Symbol.iterator]() {
			for (const [key, val] of Object.entries(this)) {
				yield {
					key,
					val,
				} as const satisfies Pbjs.BidderSettings.Common.Standard.AdserverTargeting;
			}
		},
	} as const satisfies Targeting;
}

import * as _AdjustedPrice from './AdjustedPrice';
import * as _Bid from './Bid';
import * as _PriceBucket from './PriceBucket';
import * as _TakeoverDeal from './TakeoverDeal';

export namespace Targeting {
	export import AdjustedPrice = _AdjustedPrice;
	export import Bid = _Bid;
	export import PriceBucket = _PriceBucket.PriceBucket;
	export import TakeoverDeal = _TakeoverDeal;
}
