import React from 'react';
import * as Core from '../../core';
import * as Prebid from '../../prebid';
import * as Tracking from '../../tracking';
import * as namespace from './.namespace';

export class Service extends Core.Service<Service.Settings, Service.State, Service.Context> {
	static readonly #from = (settings: Service.Settings) => new this(settings);
	static readonly use = super.Generic.Hook.from(this.#from);
	static readonly Provider = super.Generic.Provider.from(this, namespace);

	override readonly logType: Core.log.Type = 'ADMANAGER';
	override readonly Context = Service.Context;

	override useRender(enabled: boolean, children: React.ReactNode) {
		const { midscroll } = this.settings.adnami ?? {};

		return (
			<>
				{midscroll && <Core.Adnami.Midscroll {...midscroll} />}
				{super.useRender(enabled, children)}
			</>
		);
	}

	override useSetup() {
		// Invoke API here to eagerly initiate script loading:
		Service.Api.use();
	}

	override useState(enabled: boolean) {
		const config = Service.Config.use(enabled);
		const prebid = Prebid.Service.Context.use();
		const trackingManager = Tracking.Context.use();

		return React.useMemo(
			() =>
				config && prebid
					? Service.State.Active.from({ ...config, prebid, trackingManager })
					: this.Inactive,
			Core.Dependencies.whenAllChange([config, prebid]),
		);
	}

	override useMapStateToContextValue(state: Service.State) {
		return state instanceof Service.Generic.State.Inactive
			? Service.Context.defaultValue
			: state.activate;
	}
}

export namespace Service.Provider {
	export type Props = Core.Service.Generic.Provider.Props<Settings>;
}

import * as _Api from './Api';
import * as _Config from './Config';
import * as _Context from './Context';
import * as _State from './State';
import type * as _Settings from './Settings';

export namespace Service {
	export import Api = _Api.Api;
	export import Config = _Config.Config;
	export import Context = _Context.Context;
	export import State = _State.State;
	export type Settings = _Settings.Settings;
}
