import React from 'react';
import * as Core from '../../core';
import * as namespace from './.namespace';

export class Service extends Core.Service<null, Service.State, Service.Context> {
	static readonly #from = () => new this(null);
	static readonly use = super.Generic.Hook.from(this.#from);
	static readonly Provider = super.Generic.Provider.from(this, namespace);

	override readonly logType: Core.log.Type = 'ADSENSE';
	override readonly Context = Service.Context;

	override useSetup() {
		// Invoke API here to eagerly initiate script loading:
		Service.Api.use();
	}

	override useState(enabled: boolean) {
		const config = Service.Config.use(enabled);

		return React.useMemo(
			// A search query is required by Google AdSense:
			() => (config?.pageLevelParameters.query ? Service.State.Active.from(config) : this.Inactive),
			[config],
		);
	}

	override useMapStateToContextValue(state: Service.State) {
		return state instanceof Service.Generic.State.Inactive
			? Service.Context.defaultValue
			: state.activate;
	}
}

export namespace Service.Provider {
	export type Props = Core.Service.Generic.Provider.Props;
}

import * as _Api from './Api';
import * as _Config from './Config';
import * as _Context from './Context';
import * as _State from './State';

export namespace Service {
	export import Api = _Api.Api;
	export import Config = _Config;
	export import Context = _Context.Context;
	export import State = _State.State;
}
