import * as Ads from '@smd/advertising';
import * as ShoAd from '@smd/sho-advertising-typings';
import type { DataLayer } from '@smd/datalayer-typings';
import { getHttpDefaultOptions, type RecursivePartial } from '@smd/utilities';
import { IncomingHttpHeaders } from 'http';
import {curateHeaders} from "@/utils/curateHeaders";

export const get = async <T>(
  uri: URL,
  signal?: RequestInit['signal'],
  headers?: IncomingHttpHeaders
): Promise<T> => {
  const req = await fetch(uri, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...curateHeaders(headers),
    }),
    signal,
  });

  if (!req.ok) {
    throw new Error('Request to ' + uri + ' failed - ' + req.statusText);
  }

  const data = await req.json();
  return data;
};

export const post = async <T, Body = any>(
  uri: URL,
  body?: Body,
  signal?: RequestInit['signal'],
  headers?: IncomingHttpHeaders
): Promise<T> => {
  const req = await fetch(uri, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json',
      ...curateHeaders(headers),
    }),
    signal,
  });

  if (!req.ok) {
    throw new Error('Request to ' + uri + ' failed - ' + req.statusText);
  }
  if (req.headers.get('content-type')?.includes('application/json')) {
    const data = await req.json();
    return data;
  }
  return {} as T; // TODO: This can cause errors if the caller expects a specific type
};

export const del = async (
  uri: URL,
  signal?: RequestInit['signal'],
  headers?: IncomingHttpHeaders
) => {
  const req = await fetch(uri, {
    method: 'DELETE',
    headers: new Headers({ ...curateHeaders(headers) }),
    signal,
  });

  if (!req.ok) {
    throw new Error('Request to ' + uri + ' failed - ' + req.statusText);
  }
};

export async function fetchAds(
  dataLayer: RecursivePartial<DataLayer>,
  headers?: IncomingHttpHeaders
) {
  try {
    const endpoint = typeof window === 'undefined'
      ? `${process.env.API_ADVERTISING_INTERNAL_ENDPOINT}/`
      : `${window.location.origin}/api/sda-publicapi/`;

    const curatedHeaders = curateHeaders(headers);

    const config = await Ads.Core.Config.from({
      endpoint,
      intermingleCount: Number(process.env.NEXT_PUBLIC_PAGESIZE ?? 30),
      columnsCount: dataLayer.p?.pl?.includes('web') ? 1 : 3,
      dataLayer,
      supportedAdTypes: [ShoAd.AdType.Display, ShoAd.AdType.AdnuntiusDisplay],
      abortSignal: AbortSignal.timeout(500),
      headers: {
        ...getHttpDefaultOptions().headers,
        ...curatedHeaders,
        ...(curatedHeaders.cookie && {
          cookie: fetchAds.Cookies.curate(curatedHeaders.cookie),
        }),
      },
    });

    return config.isFailed ? null : config;
  } catch (error) {
    return null;
  }
}

export namespace fetchAds.Cookies {
  export const separator = '; ';

  export function curate(cookieHeader: string) {
    try {
      return Array.from(filter(cookieHeader)).join(separator);
    } catch {
      return cookieHeader;
    }
  }

  export function* filter(cookieHeader: string) {
    for (const cookie of cookieHeader.split(separator)) {
      const match = cookie.match(filter.regExp);
      if (!match) continue;

      const [, key, value] = match;

      for (const allowed of filter.allowed) {
        if (key === allowed) {
          yield `${key}=${value}`;
          break;
        }
      }
    }
  }

  export namespace filter {
    export const allowed = ['GdprConsent', 'GdprConsent-Custom'] as const;
    export const regExp = /^([^=]+)=(.*)$/;
  }
}
