import React from 'react';
import type { Nullable } from '@smd/utilities';
import type { MeridianDataLayerValue } from '@smd/datalayer-typings';
import { AsyncEffect, log, Trigger } from '../utils';
import { get } from './get';

export function use(getMeridianDataLayer: () => Nullable<MeridianDataLayerValue>) {
	const [seed, refresh] = Trigger.use(() => {
		log.error(
			'DATALAYER',
			'Resolve',
			'ForAds',
			'Refresh called on unmounted component. Make sure to update refresh reference.',
		);
	});

	// Unresolved dataLayer:
	const dataLayerLazy = React.useMemo(() => {
		log('DATALAYER', 'Resolve', 'ForAds', `Grabbing latest dataLayer, using seed ${seed}`);
		return get(getMeridianDataLayer);
	}, [getMeridianDataLayer, seed]);

	// Resolved dataLayer:
	const [dataLayer, setDataLayer] = React.useState<MeridianDataLayerValue | null>(null);

	AsyncEffect.use(() => {
		const [dataLayerLazyPromise, abortController] = dataLayerLazy;

		return {
			effect: async ({ abortSignal }) => {
				try {
					const dataLayer = await dataLayerLazyPromise;

					if (abortController?.signal.aborted || abortSignal.aborted) {
						log('DATALAYER', 'Resolve', 'ForAds', 'Cleanup ran, aborting...', {
							dataLayer,
						});
						return;
					}

					log('DATALAYER', 'Resolve', 'ForAds', 'Setting dataLayer', {
						dataLayer,
					});

					setDataLayer(dataLayer);
				} catch (error) {
					if (abortController?.signal.aborted || abortSignal.aborted) {
						log.warn('DATALAYER', 'Resolve', 'ForAds', 'Failed after cleanup ran, aborting...', {
							error,
						});
						return;
					}

					log.error('DATALAYER', 'Resolve', 'ForAds', 'Failed', {
						error,
					});
				}
			},

			cleanup: async () => {
				abortController?.abort('Effect cleanup ran');
				return Promise.resolve();
			},
		};
	}, [dataLayerLazy]);

	return [dataLayer, refresh] as const;
}
