export function from(bidCpm: number, revenueFactor: number, exchangeRate: number) {
	return (bidCpm / revenueFactor) * exchangeRate;
}

export function fromTargeting(adserverTargeting: Pbjs.Bid.Args.Common.AdserverTargeting) {
	try {
		if (!('hb_pb' in adserverTargeting)) return 0;
		if (typeof adserverTargeting.hb_pb !== 'string') return 0;

		return parseFloat(adserverTargeting.hb_pb);
	} catch {
		return 0;
	}
}

export function format(adjustedPrice: number) {
	return adjustedPrice.toFixed(3);
}
