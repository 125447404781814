import * as Core from '../../../core';
import { Targeting } from '../../Targeting';
import { Api } from '../Api';
import type { Context } from '../Context';

export type State = Core.Service.Generic.State.Of<State.Active>;

export namespace State {
	export class Active extends Core.Service.Generic.State.Active<Active.Options> {
		static readonly from = (options: Active.Options) => new this(options);

		#tracking = new Tracking(this.options);
		#bidderSettings = new BidderSettings(this.options);
		#adUnits = new AdUnits(this.options);
		#config = new Config(this.options);

		constructor(options: Active.Options) {
			super(options);
			Core.log('CONFIGURATION', 'PREBID', 'setupPrebid', 'Received configuration', options);
		}

		async runAuction(adUnitCodes: ReadonlyArray<string>, abortSignal?: AbortSignal) {
			this.abortSignal.throwIfAborted();
			abortSignal?.throwIfAborted();

			const result = await Api.execute(function () {
				return this.requestBids({ adUnitCodes, timeout: 1200 });
			}, abortSignal);

			const winningTakeoverDealId = Targeting.TakeoverDeal.getWinningId(result.auctionId);

			return { ...result, winningTakeoverDealId } as const satisfies Context.Auction;
		}

		protected override async executeSetup(abortSignal?: AbortSignal) {
			await this.#tracking.setup(abortSignal);
			await this.#bidderSettings.setup(abortSignal);
			await this.#adUnits.setup(abortSignal);
			await this.#config.setup(abortSignal);
		}

		protected override async executeDestroy(abortSignal?: AbortSignal) {
			await this.#tracking.destroy(abortSignal);
			await this.#bidderSettings.destroy(abortSignal);
			await this.#adUnits.destroy(abortSignal);
			await this.#config.destroy(abortSignal);
		}
	}

	export namespace Active {
		export type Options = AdUnits.Options &
			BidderSettings.Options &
			Config.Options &
			Tracking.Options;
	}
}

import * as _AdUnits from './AdUnits';
import * as _BidderSettings from './BidderSettings';
import * as _Config from './Config';
import * as _Tracking from './Tracking';

export namespace State {
	export import AdUnits = _AdUnits.AdUnits;
	export import BidderSettings = _BidderSettings.BidderSettings;
	export import Config = _Config.Config;
	export import Tracking = _Tracking.Tracking;
}
