import { as } from '@smd/utilities';

export type PriceBucket = `${number}.${0 | 1 | 5}0`;

export namespace PriceBucket {
	export function from(bidCpm: number): PriceBucket {
		for (const [minCpm, floorStep] of priceFloors) {
			if (bidCpm >= minCpm) continue;

			const inverted = 1 / floorStep;
			const floorPrice = Math.floor(bidCpm * inverted) / inverted;
			const priceBucket = floorPrice.toFixed(2);

			return as(priceBucket);
		}

		// all bid CPMs greater than 250.00 are considered to be in price bucket '250.00':
		return '250.00';
	}

	export type PriceFloor = readonly [minCpm: number, floorStep: number];

	// prettier-ignore
	export const priceFloors = [
		[3, 1],    // for CPMs up to 2.999, bids must be increased in increments of 1.00 CPM
		[30, 0.1], // for CPMs from 3.00 to 29.999, bids must be increased in increments of 0.10 CPM
		[70, 0.5], // for CPMs from 30.00 to 69.999, bids must be increased in increments of 0.50 CPM
		[100, 1],  // for CPMs from 70.00 to 99.999, bids must be increased in increments of 1.00 CPM
		[250, 5],  // for CPMs from 100.00 to 249.999, bids must be increased in increments of 5.00 CPM
	] as const satisfies ReadonlyArray<PriceFloor>;
}
