import React from 'react';
import { delay } from '@smd/utilities';
import { AsyncEffect, log } from '../utils';

export abstract class Service<
	TSettings extends object | null,
	TState extends Service.Generic.State = Service.Generic.State,
	TContextValue = TState,
> {
	abstract readonly logType: log.Type;
	abstract readonly Context?: React.Context<TContextValue>;

	protected constructor(protected readonly settings: TSettings) {}

	useRender(enabled: boolean, children: React.ReactNode) {
		const value = this.use(enabled);
		const { Context } = this;

		return Context ? <Context.Provider {...{ value, children }} /> : <>children</>;
	}

	abstract useSetup(): void;

	abstract useState(enabled: boolean): TState | Service.Generic.State.Inactive;

	abstract useMapStateToContextValue(state: TState | Service.Generic.State.Inactive): TContextValue;

	use(enabled: boolean): TContextValue {
		const state = this.useState(enabled);

		AsyncEffect.use(() => {
			if (state instanceof Service.Generic.State.Inactive) {
				// When state is Inactive there's nothing to set up:
				return {
					cleanup: async ({ abortSignal }) => {
						if (abortSignal.aborted) return;

						// We skip a frame to give ad slots a chance to render before we
						// execute the effect of the next state:
						await delay(16, abortSignal); // 16ms is one frame at 60fps
					},
				};
			}

			return {
				effect: async ({ abortSignal }) => {
					try {
						this.#logEffectStarting(state);
						await state.setup();

						if (abortSignal.aborted) return this.#logEffectOutdated(state);

						this.#logEffectCompleted(state);
					} catch (error) {
						abortSignal.aborted
							? this.#logEffectFailedAndAborted(state, error)
							: this.#logEffectFailed(state, error);
					}
				},

				cleanup: async ({ abortSignal }) => {
					try {
						this.#logCleanupStarted(state, abortSignal.aborted);
						await state.destroy();
						this.#logCleanupCompleted(state);
					} catch (error) {
						this.#logCleanupFailed(state, error);
					}
				},
			};
		}, [state]);

		return this.useMapStateToContextValue(state);
	}

	protected get Inactive(): Service.Generic.State.Inactive {
		this.#logInactiveState();
		return Service.Generic.State.Inactive.of();
	}

	#logEffectStarting(state: TState) {
		log(this.logType, 'Effect', 'Starting', '', { state });
	}

	#logEffectOutdated(state: TState) {
		log.warn(this.logType, 'Effect', 'Outdated', 'New config received before effect completed', {
			state,
		});
	}

	#logEffectCompleted(state: TState) {
		log(this.logType, 'Effect', 'Completed', '', { state });
	}

	#logEffectFailedAndAborted(state: TState, error: unknown) {
		log.error(
			this.logType,
			'Effect',
			'Aborted',
			`Effect could not be completed before cleanup, attempting to move forward...`,
			{ error, state },
		);
	}

	#logEffectFailed(state: TState, error: unknown) {
		log.error(this.logType, 'Effect', 'Failed', 'An error occurred during effect', {
			error,
			state,
		});
	}

	#logCleanupStarted(state: TState, aborted: boolean) {
		log(this.logType, 'Cleanup', 'Started', aborted ? 'Attempting to cleanup after unmount' : '', {
			state,
		});
	}

	#logCleanupCompleted(state: TState) {
		log(this.logType, 'Cleanup', 'Completed', '', { state });
	}

	#logCleanupFailed(state: TState, error: unknown) {
		log.error(
			this.logType,
			'Cleanup',
			'Failed',
			`Cleanup could not be completed, attempting to move forward...`,
			{ error, state },
		);
	}

	#logInactiveState() {
		log.warn(this.logType, 'Config', 'State', 'Missing options, using Inactive state...');
	}
}

export namespace Service {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	export type Any = Service<any, any, any>;

	export type Static<TSettings = object> = {
		use(settings: TSettings): Service.Any;
	};
}

import * as _State from './State';
import * as _Hook from './Hook';
import * as _Provider from './Provider';

export namespace Service.Generic {
	export import State = _State.State;
	export import Hook = _Hook;
	export import Provider = _Provider.Provider;
}
