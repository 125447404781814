import type { Nullable } from '@smd/utilities';
import * as Core from '../../core';
import * as namespace from './.namespace';
import type { Api } from './Api';

export const Context = Core.ExtendedContext.of<Context>(null, namespace.Service);

export type Context = Nullable<
	Readonly<{
		runAuction(
			adUnitCodes: ReadonlyArray<string>,
			abortSignal?: AbortSignal,
		): Promise<Nullable<Context.Auction>>;

		execute: typeof Api.execute;
	}>
>;

export namespace Context {
	export type Auction = Pbjs.RequestBids.Response & {
		readonly winningTakeoverDealId: Nullable<string>;
	};
}
