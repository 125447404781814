import type * as ShoAd from '@smd/sho-advertising-typings';
import * as Core from '../../../core';

export function* from(...adSlots: ReadonlyArray<ShoAd.IAdSlot>) {
	for (const adSlot of adSlots) {
		if (!Core.TypeGuard.Display.Slot.is(adSlot)) continue;

		const { id, prebid: adUnits } = adSlot.item.display;
		if(!adUnits) continue;

		for (const {
			mediaTypes: {
				banner: { sizes: _sizes, ...banner },
				...mediaTypes
			},
			...adUnit
		} of adUnits) {
			const sizes = _sizes?.filter(from.Sizes.excludeFluid);

			yield {
				...adUnit,
				code: id,
				mediaTypes: {
					...mediaTypes,
					banner: {
						...banner,
						...(sizes && { sizes }),
					},
				},
			} as const satisfies Pbjs.AdUnit;
		}
	}
}

export namespace from.Sizes {
	export function excludeFluid(
		size: excludeFluid.Size,
	): size is Exclude<excludeFluid.Size, 'fluid'> {
		return size !== 'fluid';
	}

	export namespace excludeFluid {
		export type Size = NonNullable<ShoAd.PrebidBannerMediaTypes['sizes']>[number];
	}
}
