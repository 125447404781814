import { as, ensure, PromiseWithResolvers } from '@smd/utilities';
import * as Core from '../../core';
import * as namespace from './.namespace';

class ApiLoader extends Core.ApiLoader<Api> {
	override readonly globalKey = 'pbjs';
	protected override readonly namespace = namespace;

	protected override get scriptUrl(): never {
		throw new Error('N/A');
	}

	override async execute<R>(executor: Core.ApiLoader.Executor<Api, R>, abortSignal?: AbortSignal) {
		abortSignal?.throwIfAborted();

		const globalRef = await this.load();
		const { promise, resolve, reject } = PromiseWithResolvers.of<R>();

		globalRef.que.push(() => {
			try {
				abortSignal?.throwIfAborted();
				resolve(executor.apply(globalRef, [abortSignal]));
			} catch (error) {
				reject(error);
			}
		});

		return await promise;
	}

	protected override async resolveReference() {
		const globalRef = ensure(window[this.globalKey]);
		const { promise, resolve, reject } = PromiseWithResolvers.of<Api>();

		try {
			globalRef.que.push(() => resolve(globalRef));
		} catch (error) {
			reject(error);
		}

		return await promise;
	}

	protected override async shouldLoad() {
		return Promise.resolve(true);
	}

	protected override async prepare() {
		const globalRef = (window[this.globalKey] ??= as({}));
		(globalRef.que as typeof globalRef.que | undefined) ??= new Array();

		return Promise.resolve();
	}

	protected override createLoadScript(): Core.ApiLoader.LoadScript {
		let status: 'ready' | 'pending' | 'fulfilled' | 'rejected' = 'ready';

		return Object.assign(
			async function loadScript() {
				try {
					status = 'pending';
					await import('../../../lib/prebid');
					status = 'fulfilled';
				} catch (error) {
					status = 'rejected';
					throw error;
				}
			},
			{
				isReady: () => status === 'ready',
				isPending: () => status === 'pending',
				isFulfilled: () => status === 'fulfilled',
				isRejected: () => status === 'rejected',
			},
		);
	}
}

export const Api = new ApiLoader();

export type Api = Pbjs;

export namespace Api {
	export type Window = { [Api.globalKey]?: Api | undefined };
}

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window extends Api.Window {}
}
