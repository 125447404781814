import React from 'react';
import { canUseDOM, DomUnavailableError, dynamicScriptLoader, LazyPromise } from '@smd/utilities';
import type { MaybePromise } from '@smd/utilities';
import type { Service } from '../Service';
import { log } from './log';
import * as AsyncEffect from './AsyncEffect';
import * as namespace from '../.namespace';

export abstract class ApiLoader<T> {
	get name() {
		return (this.#name ??= this.namespace.Service.nameof({ ApiLoader }));
	}

	abstract readonly globalKey: string;

	protected abstract readonly namespace: Service.Generic.Provider.from.Namespace;
	protected abstract readonly scriptUrl: `https://${string}`;

	#name?: string | undefined;
	#promise?: LazyPromise<T> | undefined;
	#loadScript?: ApiLoader.LoadScript | undefined;

	abstract execute<R>(executor: ApiLoader.Executor<T, R>, abortSignal?: AbortSignal): Promise<R>;

	load() {
		return (this.#promise ??= new LazyPromise<T>((resolve, reject) => {
			this.#executor().then(resolve, reject);
		}));
	}

	use() {
		const [[api], setApi] = React.useState<[T | null]>([null]);

		AsyncEffect.use(() => {
			return {
				effect: async ({ abortSignal }) => {
					try {
						const api = await this.load();
						if (!abortSignal.aborted) setApi([api]);
					} catch (error) {
						this.#logFailure(error);
					}
				},
			};
		}, [setApi]);

		return api;
	}

	protected abstract shouldLoad(): MaybePromise<boolean>;

	protected abstract prepare(): MaybePromise<void>;

	protected abstract resolveReference(): T | PromiseLike<T>;

	protected createLoadScript(): ApiLoader.LoadScript {
		return dynamicScriptLoader(this.scriptUrl);
	}

	async #executor() {
		try {
			if (!canUseDOM()) throw new DomUnavailableError();
			if (!(await this.shouldLoad())) throw new ApiLoader.UnmetLoadConditionError();

			this.#loadScript ??= this.createLoadScript();
			const ready = this.#loadScript.isReady();

			if (ready) {
				this.#logPreparing();
				await this.prepare();
			}

			if (ready) this.#logLoading();
			await this.#loadScript();

			const api = await this.resolveReference();
			if (ready) this.#logResolved(api);

			return api;
		} catch (error) {
			if (error instanceof ApiLoader.UnmetLoadConditionError) this.#logUnmetLoadCondition();
			else if (error instanceof ApiLoader.UnavailableError) this.#logUnmetLoadCondition();
			else this.#logFailure(error);

			throw error;
		}
	}

	#logUnmetLoadCondition() {
		log.warn('LOAD', this.name, this.globalKey, 'Loading condition not met, aborting...');
	}

	#logPreparing() {
		log('LOAD', this.name, this.globalKey, 'Preparing...');
	}

	#logLoading() {
		log('LOAD', this.name, this.globalKey, 'Loading...');
	}

	#logResolved(api: T) {
		log('LOAD', this.name, this.globalKey, 'Resolved', { api });
	}

	#logFailure(error: unknown) {
		log.error('LOAD', this.name, this.globalKey, 'Failed!', { url: this.scriptUrl, error });
	}
}

export namespace ApiLoader {
	export type Executor<T, R> = (this: T, abortSignal?: AbortSignal) => R;

	export type LoadScript =
		ReturnType<typeof dynamicScriptLoader> extends (() => Promise<Event>) & infer T
			? (() => Promise<unknown>) & T
			: never;

	const nameof = namespace.Core.nameof.prefix({ ApiLoader });

	export class UnavailableError extends ReferenceError {
		static {
			this.prototype.name = nameof({ UnavailableError });
		}

		constructor(globalName: string) {
			super(`Expected required "${globalName}" to be available!`);
		}
	}

	export class UnmetLoadConditionError extends Error {
		static {
			this.prototype.name = nameof({ UnmetLoadConditionError });
		}

		constructor() {
			super('The condition for loading was not satisfied!');
		}
	}
}
